import { useEffect, useState } from "react";
import { CampaignsService } from "../../services/campaings.service";
import { Link } from "react-router-dom";
import { AlertService } from "../../services/alert.service";
import useBgPages from "../../hooks/bg-pages.hook";
import useRequest from "../../hooks/use-request.hook";
import Aside from "../../components/layout/asaid.component";
import Header from "../../components/layout/header.component";
import Footer from "../../components/layout/footer.component";
import Datatable from "../../components/datatable/datatable.component";
import moment from "moment";
import AddBase from "./base-modal";

interface File {
  original_name: string;
  key_s3: string;
  _id: string;
}

interface Typification {
  label: string;
}

interface Campaigns {
  _id: string;
  name: string;
  file: File;
  blacklists: string[];
  typifications: Typification[];
  product: string;
  dialer: string;
  status: string;
  total_blocked_blacklist: number;
  total_blocked_pnn: number;
  total_registers: number;
  updated_at: string;
  created_at: string;
}

interface StatisticsProps {
  iconClass: string;
  count: number;
  label: string;
  iconColor: string;
}

interface CSVData {
  [key: string]: string;
}

function ListCampaignsPage() {
  useBgPages();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<Campaigns[]>([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [totalActive, setTotalActive] = useState<number>(0);
  const [totalInactive, setTotalInactive] = useState<number>(0);
  const [totalProcessing, setTotalProcessing] = useState<number>(0);
  const [totalError, setTotalError] = useState<number>(0);

  const [showModal, setShowModal] = useState({
    show: false,
    data: null,
  });

  const columns = [
    {
      name: "Datos",
      sortable: true,
      width: 15,
      cell: (row: any) => {
        return (
          <div className="text-nowrap">
            <h5>
              Campaña: <span className="text-muted"> {row.name}</span>
            </h5>
            <h5>
              Prodcuto: <span className="text-muted"> {row.product.name}</span>
            </h5>
          </div>
        );
      },
    },
    {
      name: "Fecha de Creación",
      sortable: true,
      width: 15,
      cell: (rows: any) => {
        const fechaHora = moment(rows.created_at);
        const fecha = fechaHora.format("DD-MM-YYYY");
        const hora = fechaHora.format("HH:mm:ss");

        return (
          <div className="text-nowrap">
            <h5>
              Fecha: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              Hora: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      },
    },
    {
      name: "Status",
      sortable: false,
      center: true,
      exportable: false,
      width: 15,
      cell: (rows: any) => {
        const renderBadge = (
          iconClass: string,
          colorClass: string,
          text: string,
          animate = false,
        ) => (
          <span className={`badge ${colorClass} fs-6`}>
            <i
              className={`${iconClass} fs-3 me-1 ${animate ? "refresh-animate" : ""}`}
            >
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {text}
          </span>
        );

        switch (rows.status?.toLowerCase()) {
          case "active":
            return renderBadge(
              "ki-duotone ki-check-circle text-primary",
              "badge-light-primary",
              "Activa",
            );
          case "inactive":
            return renderBadge(
              "ki-duotone ki-minus-circle text-danger",
              "badge-light-danger",
              "Inactiva",
            );
          case "ucontact_error":
            return renderBadge(
              "ki-duotone ki-cross-circle text-danger",
              "badge-light-danger",
              "Error UContact",
            );
          case "processing":
            return renderBadge(
              "ki-duotone ki-abstract-20 text-info",
              "badge-light-info",
              "Procesando",
              true,
            );
          default:
            return renderBadge(
              "ki-duotone ki-notification-circle text-warning",
              "badge-light-warning",
              rows.status?.toLowerCase(),
            );
        }
      },
    },
    {
      name: "Resultados",
      sortable: true,
      center: true,
      width: 15,
      cell: (rows: any) => {
        if (rows.status?.toLowerCase() === "processing") {
          return <span className="fw-bolder h5">Recoplinado datos...</span>;
        }

        return (
          <div className="text-nowrap">
            <div className="d-flex h5 fw-semibold align-items-center">
              <div className="bullet w-15px h-6px mb-2 rounded-2 bg-primary" />
              <div className="ms-2 h5 fw-bolder text-muted">
                {rows.total_registers.toLocaleString()}
              </div>
              <div className="ms-2 h5 fw-semibold flex-shrink-0">
                Registros encontrados
              </div>
            </div>

            <div className="d-flex h5 fw-semibold align-items-center">
              <div className="bullet w-15px h-6px mb-2 rounded-2 bg-info" />
              <div className="ms-2 h5 fw-bolder text-muted">
                {rows.total_blocked_pnn.toLocaleString()}
              </div>
              <div className="ms-2 h5 fw-semibold flex-shrink-0">
                Bloqueados por PNN
              </div>
            </div>

            <div className="d-flex h5 fw-semibold align-items-center">
              <div className="bullet w-15px h-6px mb-2 rounded-2 bg-danger" />
              <div className="ms-2 h5 fw-bolder text-muted">
                {rows.total_blocked_blacklist.toLocaleString()}
              </div>
              <div className="ms-2 h5 fw-semibold flex-shrink-0">
                Bloqueados por Blacklist
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Acciones",
      sortable: false,
      center: true,
      exportable: false,
      width: 15,
      cell: (rows: any) => {
        return (
          <>
            <div className="text-nowrap">
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                Acciones
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-link text-muted fw-bolder px-3 ms-4 fs-7 text-uppercase d-flex justify-content-start">
                  Acción
                </div>

                <div className="menu-item px-3">
                  {rows.status !== "UCONTACT_ERROR" &&
                    rows.status !== "PROCESSING" && (
                      <a
                        className="menu-link px-3"
                        onClick={() => changeStatus(rows._id, rows.status)}
                      >
                        {rows.status === "INACTIVE" ? (
                          <>
                            <i className="ki-duotone ki-check-circle fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            <span className="ms-2">Activar</span>
                          </>
                        ) : rows.status === "ACTIVE" ? (
                          <>
                            <i className="ki-duotone ki-cross-square fs-1 text-danger">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                            <span className="ms-2">Desactivar</span>
                          </>
                        ) : null}
                      </a>
                    )}
                </div>

                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() =>
                      setShowModal({
                        show: true,
                        data: { ...rows, type: "update" },
                      })
                    }
                  >
                    <>
                      <i className="ki-duotone ki-abstract-20 fs-1 text-info">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-2">Actualizar base</span>
                    </>
                  </a>
                </div>

                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() =>
                      setShowModal({
                        show: true,
                        data: { ...rows, type: "append" },
                      })
                    }
                  >
                    <>
                      <i className="ki-duotone ki-abstract-10 fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-2">Anexar nueva base</span>
                    </>
                  </a>
                </div>

                <div className="menu-item px-3 mb-3">
                  <a
                    className="menu-link px-3"
                    onClick={() =>
                      setShowModal({
                        show: true,
                        data: { ...rows, type: "filtered" },
                      })
                    }
                  >
                    <>
                      <i className="ki-duotone ki-file-up  fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-2">Subir base filtrada</span>
                    </>
                  </a>
                </div>

                <div className="menu-link text-muted fw-bolder px-3 ms-4 fs-7 text-uppercase d-flex justify-content-start">
                  Descargas
                </div>

                {/* <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() =>
                      download(rows._id, "db", rows.file.original_name)
                    }
                  >
                    <i className="ki-duotone ki-file-down fs-1 fw-bolder">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Archivo TXT</span>
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => download(rows._id, "ucontact", "")}
                  >
                    <i className="ki-duotone ki-cloud-download fs-1 fw-bolder">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Base subida a uContact</span>
                  </a>
                </div> */}
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => download(rows._id, "db", "")}
                  >
                    <i className="ki-duotone ki-cloud-download fs-1 fw-bolder">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Base subida</span>
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => downloadRegisters(rows._id, rows.name)}
                  >
                    <i className="ki-duotone ki-questionnaire-tablet fs-1 fw-bolder">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Registros de campaña</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const getCampaigns = async () => {
    try {
      const response = await call(() => CampaignsService.getLisCampaigns());

      if (response && Array.isArray(response)) {
        let activeCount = 0;
        let inactiveCount = 0;
        let processingCount = 0;
        let errorCount = 0;

        response.forEach((campaign) => {
          switch (campaign.status) {
            case "ACTIVE":
              activeCount++;
              break;
            case "INACTIVE":
              inactiveCount++;
              break;
            case "PROCESSING":
              processingCount++;
              break;
            case "CONTACT_ERROR":
              errorCount++;
              break;
            default:
              break;
          }
        });

        setTotalActive(activeCount);
        setTotalInactive(inactiveCount);
        setTotalProcessing(processingCount);
        setTotalError(errorCount);

        setTotalCampaigns(response.length);
        setRows(response);
        AlertService.toastSuccess(
          "¡ Éxito !, Información actualizada correctamente.",
        );
      }
    } catch (error) {
      console.error("Error al obtener las campañas:", error);
      AlertService.toastError(
        "¡ Atención !, Error al obtener la información de las campañas, contacta a tu administrador.",
      );
    }
  };

  const Statistics: React.FC<StatisticsProps> = ({
    iconClass,
    count,
    label,
    iconColor,
  }) => {
    return (
      <div className="alert alert-dismissible border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
        <div className="d-flex align-items-center">
          <i className={`${iconClass} fs-2x hover-scale me-2 ${iconColor}`}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="fs-2 fw-bold">{count.toLocaleString()}</div>
        </div>
        <div className="fw-bolder fs-4 text-muted">{label}</div>
      </div>
    );
  };

  const changeStatus = async (id: string, status: "ACTIVE" | "INACTIVE") => {
    const newStatus = status === "ACTIVE" ? false : true;
    try {
      const confirmed = await AlertService.question(
        "¡ Atención !",
        `¿Deseas ${newStatus ? "activar" : "desactivar"} la campaña?, puedes revertir esta acción más adelante.`,
        null as any,
        newStatus ? "Activar" : "Desactivar",
      );
      if (!confirmed) {
        return;
      }
      await CampaignsService.changeStatus(id, newStatus);
      AlertService.toastSuccess(
        "¡ Éxito !, Se actualizó el status de la campaña correctamente.",
      );
      getCampaigns();
    } catch (error) {
      console.log(error);
      AlertService.toastError(
        "¡ Atención !, La campaña no pudo Actualizarse, contacta al administrador.",
      );
    }
  };

  const download = async (id: string, type: string, name: string) => {
    const promise = async () => {
      const response = await CampaignsService.downloadFile(id, type);

      if (response?.url) {
        const a = document.createElement("a");
        a.href = response.url;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    };

    AlertService.toastLoading(
      promise(),
      "Descargando archivo, por favor espera..",
      "¡ Éxito !, Archivo descargado correctamente.",
      "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
    );
  };

  const downloadRegisters = async (id: string, name: string) => {
    const parseCSV = (csvString: string): CSVData[] => {
      const lines = csvString
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);
      const headers = lines[0]
        .split(",")
        .map((header) => header.replace(/"/g, "").trim());

      const data: CSVData[] = lines.slice(1).map((line) => {
        const values = line
          .split(",")
          .map((value) => value.replace(/"/g, "").trim());
        return headers.reduce((obj: CSVData, header, index) => {
          obj[header] = values[index] || "";
          return obj;
        }, {});
      });

      return data;
    };

    const generateCSV = (data: CSVData[]) => {
      if (data.length === 0) return "";
      const headers = Object.keys(data[0]);
      const rows = data.map((item) =>
        headers.map((header) => item[header]).join(","),
      );

      return [headers.join(","), ...rows].join("\n");
    };

    const downloadFile = (data: string, filename: string) => {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };

    const promise = async () => {
      try {
        const response = await CampaignsService.getRegisters(id);
        if (typeof response === "string" && response.trim()) {
          const dataArray = parseCSV(response);
          const csv = generateCSV(dataArray);
          downloadFile(csv, `registros_campaña_${name}.csv`);
        } else {
          console.error("La respuesta no es una cadena válida.");
          AlertService.toastError(
            "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
          );
        }
      } catch (error) {
        AlertService.toastError(
          "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
        );
        console.error("Error al descargar los registros:", error);
      }
    };

    AlertService.toastLoading(
      promise(),
      "Descargando archivo, por favor espera..",
      "¡ Éxito !, Archivo descargado correctamente.",
      "¡ Atención !, No pudimos descargar el archivo, contacta al administrador.",
    );
  };

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await getCampaigns();
    }
    setShowModal({ show: false, data: null });
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-7 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Campañas</li>
                    <li className="breadcrumb-item text-muted"></li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-tablet-book fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-3">Mis campañas</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-7 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-7 mb-4">
                        <i className="ki-duotone ki-chart-pie-3 fs-4x hover-scale me-2 mt-10">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <span className="text-gray-900 fs-2 fw-bold me-1">
                                Estadísticas
                              </span>
                            </div>
                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                              <span className="d-flex align-items-center text-gray-600 text-hover-primary me-2 mb-2 fs-5">
                                Total campañas registradas:
                              </span>
                              <span className="d-flex align-items-center text-primary fs-3 fw-bolder text-hover-primary mb-3">
                                {totalCampaigns.toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <Statistics
                                iconClass="ki-duotone ki-check-circle"
                                count={totalActive}
                                label="Activas"
                                iconColor="text-primary"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-abstract-20 refresh-animate"
                                count={totalProcessing}
                                label="Procesando"
                                iconColor="text-info"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-minus-circle"
                                count={totalInactive}
                                label="Inactivas"
                                iconColor="text-danger"
                              />
                              <Statistics
                                iconClass="ki-duotone ki-cross-circle"
                                count={totalError}
                                label="Error uContact"
                                iconColor="text-danger"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-250px w-sm-450px">
                            <div className="d-flex justify-content-end w-100 mt-auto mb-2">
                              <button
                                type="button"
                                className="btn btn-light-info fw-bold me-2"
                                onClick={getCampaigns}
                              >
                                <i className="ki-duotone ki-abstract-20 fs-1">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                                Actualizar
                              </button>
                              <Link
                                className="btn btn-primary fw-bold"
                                to="/sendCampaigns"
                              >
                                <i className="ki-duotone ki-send fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                                Cargar campaña
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                      <li className="nav-item"></li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </div>
                <div className="row g-xl-12">
                  <div className="col-xl-12 mb-5">
                    <div className="card">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            Campañas
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-7">
                            Listado
                          </span>
                        </h3>
                      </div>
                      <div className="card-body pt-1">
                        <Datatable
                          columns={columns}
                          data={rows}
                          isLoading={isRequesting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddBase
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
        payload={showModal.data}
      />
    </>
  );
}
export default ListCampaignsPage;
